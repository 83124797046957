<template>
  <div class="mt-32 lg:mt-52">
    <div v-if="canShowContent">
      <Content model="page" :content="content" :api-key="apiKey" :customComponents="getRegisteredComponents()" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { fetchOneEntry, Content, isPreviewing } from '@builder.io/sdk-vue';
import ProductCard from '~/components/shared/ProductCard.vue';

const route = useRoute();
const config = useRuntimeConfig();

const { locale } = useI18n();

const canShowContent = ref(false);
const apiKey = config.public.BUILDER_IO_API_KEY;

const content = await fetchOneEntry({
  model: 'page',
  apiKey,
  options: {
    userAttributes: {
      urlPath: route.path,
      locale: locale.value,
    },
    locale: locale.value,
  },
});

onMounted(() => {
  canShowContent.value = content || isPreviewing();

  if (!canShowContent.value) {
    throw showError({
      statusCode: 404,
      message: 'Page not found',
    });
  }
});

const registeredComponents = [
  {
    component: ProductCard,
    name: 'Product Card',
    inputs: [
      {
        name: 'product',
        type: 'object',
        defaultValue: {
          id: 1,
          name: 'Product Name',
          subtitle: 'Product Subtitle',
          slug: 'product-name',
          price: 100,
          sale_price: 90,
          thumbnail: 'https://cdn.builder.io/api/v1/image/assets%2F-LzF2Zz3v3J3b8Jz3X8n%2F-LzF2Zz3v3J3b8Jz3X8n',
        },

        subFields: [
          {
            name: 'id',
            type: 'number',
            defaultValue: 1,
          },
          {
            name: 'name',
            type: 'string',
            defaultValue: 'Product Name',
          },
          {
            name: 'subtitle',
            type: 'string',
            defaultValue: 'Product Subtitle',
          },
          {
            name: 'slug',
            type: 'string',
            defaultValue: 'product-name',
          },
          {
            name: 'price',
            type: 'number',
            defaultValue: 100,
          },
          {
            name: 'sale_price',
            type: 'number',
            defaultValue: 90,
          },
          {
            name: 'thumbnail',
            type: 'string',
            defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F-LzF2Zz3v3J3b8Jz3X8n%2F-LzF2Zz3v3J3b8Jz3X8n',
          },
        ],
      },
    ],
  },
];

function getRegisteredComponents() {
  return registeredComponents;
}

useSeoMeta({
  title: content?.data?.meta?.title,
  description: content?.data?.meta?.description,
  ogTitle: content?.data?.meta?.title,
  ogDescription: content?.data?.meta?.description,
});
</script>
